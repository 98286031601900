import Header from "../header/Header";
import TabFilter from "../tabFilter/TabFilter";

function Home() {
  return (
    <div>
        <Header/>
        <TabFilter/>
    </div>
  );
}
export default Home;
