import {React, useContext, useState} from "react";
import { StateContext } from "../../provider/StateProvider";

function ProductCategoryCenterSearch() {
  const { productCategory } = useContext(StateContext);
  const [openTab, setOpenTab] = useState(1);
  return (
    <div>
      <ul
          className="flex mb-0 list-none flex-wrap pt-3 flex-row"
          role="tablist"
        >
          {productCategory.map((item, i) => (
          <li className="-mb-px mr-2 last:mr-0 flex-auto text-center"  key={i}>
            <a
              className={
                "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                (openTab === i
                  ? "text-white bg-blue-600"
                  : "text-blue-600 bg-white")
              }
              onClick={e => {
                e.preventDefault();
                setOpenTab(i);
              }}
              data-toggle="tab"
              href="#link1"
              role="tablist"
            >
             {item?.Description}
            </a>
          </li>
           ))}
        </ul>
    </div>
  );
}

export default ProductCategoryCenterSearch;
