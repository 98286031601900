import imgHome from "../../img/circular.jpeg";
import TabFilter from "../tabFilter/TabFilter";
function Comp03104683663() {
  const imgHomeUrl = imgHome;
  return (
    <div>
      
      <img
        className="relative w-full h-full xs:absolute"
        src={imgHomeUrl}
        alt=""
      />
      
      <TabFilter />
      
    </div>
  );
}

export default Comp03104683663;
