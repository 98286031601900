import React, { createContext, useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axios from "axios";
export const StateContext = createContext();

export function StateProvider(props) {
  const MySwal = withReactContent(Swal);
  const [basket, setBasket] = useState([]);
  const [user, setUser] = useState(null);
  const [company, setCompany] = useState(null);
  const [productCategory, setProductCategory] = useState([]);
  const [productType, setProductType] = useState([]);
  const [city, setCity] = useState([]);
  const [province, setProvince] = useState([]);
  const [load, setLoad] = useState(false);
  const [itemProduct, setItemProduct] = useState({});
 
  const apiRegistry = axios.create({
    baseURL: `http://108.181.162.121:3619/adminSoftSystem/registry/`,
  });
  const apiInventory = axios.create({
    baseURL: `http://108.181.162.121:3608/adminSoftSystem/api/`,
  });
  const apiMaintenance = axios.create({
    baseURL: `http://108.181.162.121:3617/adminSoftSystem/api/`,
  });
  const addToBasket = (id, title, image, price, rating) => {
    setBasket([
      ...basket,
      {
        id: id,
        title: title,
        image: image,
        price: price,
        rating: rating,
      },
    ]);
  };

  const addITemProduct = (item) =>{
    console.log("Entre al AddITEM ", item)
    setItemProduct(item);
  };

  const addProductCategory = (list) => {
    setProductCategory(list);
  };
  const addProductType = (list) => {
    let NewList = [{ Id: "", Description: "Todos" }];
    list.map((item, index) =>
      NewList.push({ Id: item.Id, Description: item.Description })
    );
    setProductType(NewList);
  };
  const addCity = (list) => {
    let NewList = [{ Id: "", Description: "Todas las Ciudades" }];
    list.map((item, index) =>
      NewList.push({ Id: item.Id, Description: item.Description })
    );
    setCity(NewList);
  };
  const addProvince = (list) => {
    let NewList = [{ Id: "", Description: "Todas las Provincias" }];
    list.map((item, index) =>
      NewList.push({ Id: item.Id, Description: item.Description })
    );
    setProvince(NewList);
  };
  const removeFromBasket = (id) => {
    setBasket(basket.filter((n) => n.id !== id));
  };

  const getBasketTotal = (basket) =>
    basket?.reduce((amount, item) => item.price + amount, 0);

  const updateUserData = () => {
    if(localStorage.getItem("currentUser") !== "undefined"){
      setUser(JSON.parse(localStorage.getItem("currentUser")));
    }
  };
  const updateCompanyData = (item) => {
    setCompany(item);
  };

  const handleAuthenticaton = () => {
    if (user) {
      localStorage.setItem("currentUser", null);
      localStorage.removeItem("currentUser");
      setUser(null);
    }
  };

  const removeComma = (item) => {
    if (item === null || item === undefined || item === "") {
      item = "";
    }
    if (isNaN(item)) {
      return item
        .replace(",", "")
        .replace(",", "")
        .replace(",", "")
        .replace(",", "")
        .replace(",", "");
    } else {
      return item;
    }
  };

  const convertToNumber = (item) => {
    if (item === null || item === undefined || item === "") {
      item = "0";
    } else {
      item = removeComma(item);
      if (isNaN(item)) {
        item = "0";
      }
    }
    return Number(item);
  };

  const formatToNumber = (item) => {
    let optionsNumber = {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    };
    return convertToNumber(item).toLocaleString("en", optionsNumber);
  };

  const alertMsg = (text, ref = null, title = "Eroor!", icon = "E") => {
    MySwal.fire({
      title: title,
      text: text,
      icon: icon === "S" ? "success" : "error",
      confirmButtonText: "ok",
    }).then((result) => {
      if (result.isConfirmed) {
        if (ref != null) {
          setTimeout(() => {
            ref.current.focus();
          }, 800);
        }
      }
    });
  };

  const loadIndicator = (_load) => {
    setLoad(_load);
  };
  return (
    <StateContext.Provider
      value={{
        basket,
        user,
        company,
        productCategory,
        productType,
        city,
        province,
        load,
        itemProduct,
        apiRegistry,
        apiInventory,
        apiMaintenance,
        addToBasket,
        removeFromBasket,
        getBasketTotal,
        updateUserData,
        updateCompanyData,
        handleAuthenticaton,
        addProductCategory,
        addProductType,
        addCity,
        addProvince,
        addITemProduct,
        removeComma,
        convertToNumber,
        formatToNumber,
        alertMsg,
        loadIndicator,
        
      }}
    >
      {props.children}
    </StateContext.Provider>
  );
}
