import { useState, useContext } from "react";
import "./Login.css";
import { StateContext } from "../../provider/StateProvider";
import { useNavigate } from "react-router-dom";
import axios from "axios";
const apiWeb = axios.create({
  baseURL: `http://108.181.162.121:3619/adminSoftSystem/registry/getSystemUserWeb/`,
});

function Login() {
  const { company, alertMsg, loadIndicator, load,  updateUserData } = useContext(StateContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const signIn = (e) => {
    e.preventDefault();
    loadIndicator(true);
    if (load) return;
    let data = { UserName: email, PasswordHash: password };
    let config = {
      headers: {
        Authorization: "Bearer " + company?.AccessToken,
      },
    };
    apiWeb
      .post("/", JSON.stringify(data), config)
      .then((res) => {
        if (
          res?.data?.DESCRIPTION_ERROR === undefined ||
          res?.data?.DESCRIPTION_ERROR === null ||
          res?.data?.DESCRIPTION_ERROR === ""
        ) {
          localStorage.removeItem('currentUser');
          localStorage.setItem('currentUser', null);
          localStorage.setItem('currentUser', JSON.stringify(res?.data.Data));
          updateUserData();
          navigate('/');
        } else {
          alertMsg(res?.data?.DESCRIPTION_ERROR);
        }

        loadIndicator(false);
        
      })
      .catch((err) => {
        if (
          (err?.data?.DESCRIPTION_ERROR !== undefined &&
            err?.data?.DESCRIPTION_ERROR !== null) ||
          err?.data?.DESCRIPTION_ERROR !== ""
        ) {
          alertMsg(err?.data?.DESCRIPTION_ERROR);
        } else {
          alertMsg(err);
        }
        loadIndicator(false);
      });
  };

  return (
    <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="w-full max-w-md space-y-8">
        <div>
          <img
            className="mx-auto h-12 w-auto"
            src={company?.Photo?.Photo}
            alt={company?.Name}
          />
          <h1 className="mt-6 text-center text-4xl font-bold tracking-tight text-gray-900">
            {company?.Name}
          </h1>
          <p className="mt-2 text-center text-sm text-gray-600">
            <a className="font-medium text-indigo-600 hover:text-indigo-500">
              {company?.Slogan}
            </a>
          </p>
          <h2 className="mt-6 text-center text-2xl font-bold tracking-tight text-gray-900">
            Iniciar sesión en su cuenta
          </h2>
        </div>
        <form className="mt-8 space-y-6" onSubmit={signIn}>
          <input type="hidden" name="remember" defaultValue="true" />
          <div className="-space-y-px rounded-md shadow-sm">
            <div>
              <label htmlFor="email-address" className="sr-only">
                Correo electrónico
              </label>
              <input
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                required
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                placeholder="Correo electrónico"
              />
            </div>
            <div>
              <label htmlFor="password" className="sr-only">
                Contraseña
              </label>
              <input
                id="password"
                name="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                autoComplete="current-password"
                required
                className="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                placeholder="Contraseña"
              />
            </div>
          </div>

          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <input
                id="remember-me"
                name="remember-me"
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
              />
              <label
                htmlFor="remember-me"
                className="ml-2 block text-sm text-gray-900"
              >
                Acuérdate
              </label>
            </div>

            <div className="text-sm">
              <a
                href="#"
                className="font-medium text-indigo-600 hover:text-indigo-500"
              >
                ¿Olvidaste tu contraseña?
              </a>
            </div>
          </div>

          <div>
            <button
            href={"/ecommerce/login"}
              type="submit"
              className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              <span className="absolute inset-y-0 left-0 flex items-center pl-3"></span>
              Iniciar sesión
            </button>
            <a
              href={"/ecommerce/register"}
              type="button"
              className="group relative flex w-full justify-center rounded-md border border-transparent bg-orange-600 py-2 px-4 text-sm font-medium text-white hover:bg-orange-700 focus:outline-none focus:ring-2
               focus:ring-indigo-500 focus:ring-offset-2 my-2"
            >
              <span className="absolute inset-y-0 left-0 flex items-center pl-3"></span>
              Regístrate
            </a>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
