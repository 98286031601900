import { useContext, useState } from "react";
import { StateContext } from "../../provider/StateProvider";
import "./AddProduct.css";
import ViewProductModal from "./ViewProductModal";
import RemoveRedEyeRounded from '@material-ui/icons/RemoveRedEyeRounded';
import AddShoppingCart from '@material-ui/icons/AddShoppingCart';
import { useNavigate } from 'react-router-dom';

function AddProduct({ item }) {
  const [showModal, setShowModal] = useState(false);
  const { company, formatToNumber, addITemProduct } = useContext(StateContext);
  const navigator  = useNavigate();
  const urlImg =
    "http://108.181.162.121:3608/adminSoftSystem/api/viewPhotoProduct/" +
    company.Id +
    "/";
  const intDefaultImg = item.PhotoProduct.filter(
    (n) => n.metadata.IsDefault === "Y"
  );

  const srcDefaultImg =
    intDefaultImg.length > 0
      ? urlImg + intDefaultImg[0]._id
      : "https://via.placeholder.com/350x380/6495ED/000000";

  const productUnitBilling = item.ProductUnit.filter(
    (n) => n.UnitBillingOrderQuote === "X"
  );

  const price1 =
    productUnitBilling.length > 0
      ? formatToNumber(productUnitBilling[0].Price1)
      : "0.00";

 
  return (
    <div className="w-full max-w-sm bg-white rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-700">
      <a href="#">
        <img
          className="p-8 rounded-t-lg h-48 w-full"
          src={srcDefaultImg}
          alt="product image"
        />
      </a>
      <div className="px-5 pb-5">
        <a href="#">
          <h5 className="text-xl font-semibold tracking-tight text-gray-900 dark:text-white">
            {item.Name}
          </h5>
        </a>
        <div className="mt-2.5 mb-5 overflow-y-scroll">
          <div
            className="h-48"
            dangerouslySetInnerHTML={{ __html: item.Description }}
          />
        </div>
        <p>
          <span className="text-3xl font-bold text-gray-900 dark:text-white">
            ${price1}
          </span>
        </p>
        <div className="flex justify-between items-center py-3">
          <a onClick={() => {
              addITemProduct(item);
              let url = "/viewProductDetail/"+ company.Id+"/"+item.Id;
              navigator(url);
            }}  >
            <RemoveRedEyeRounded color="primary" />
          </a>
          <AddShoppingCart color="primary" />
        </div>
      </div>
      {/* {showModal ? (
        <ViewProductModal item={item} urlImg={urlImg} closeModal={closeModal} />
      ) : null} */}
    </div>
  );
}
export default AddProduct;
