import { React, useContext, useState } from "react";
import { StateContext } from "../../provider/StateProvider";
function SearchFields({ clickGetProductPublic }) {
  const { province, productType } = useContext(StateContext);
  const [provinceSearch, setProvinceSearch] = useState("");
  const [habitacioneMin, setHabitacioneMin] = useState(0);
  const [habitacioneMax, setHabitacioneMax] = useState(0);
  const [preciosMin, setPreciosMin] = useState(0);
  const [preciosMax, setPreciosMax] = useState(0);
  const [tipoPropiedad, setTipoPropiedad] = useState("");

  const handleChangeProvince = (event) => {
    setProvinceSearch(event.target.value);
  };

  const handleChangeProductType = (event) => {
    setTipoPropiedad(event.target.value);
  };

  const click_GetProductPublic = () => {
    clickGetProductPublic(
      "",
      tipoPropiedad,
      "",
      "",
      preciosMin,
      preciosMax,
      habitacioneMin,
      habitacioneMax,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      provinceSearch
    );
  };
  return (
    <>
      <nav className="relative bg-white">
        <div className="mx-auto max-w-8xl px-2 mt-2 sm:px-3">
          {/* <div className="flex items-center justify-between border-b-2 border-gray-100 py-3 md:justify-start md:space-x-10"> */}
            <div className="md:flex lg:flex justify-center">
              <div className="col-span-12 sm:col-span-6">
                <label
                  htmlFor="country"
                  className="block text-sm font-medium text-gray-700"
                >
                  Provincia
                </label>
                <select
                  id="province"
                  name="province"
                  autoComplete="province-name"
                  onChange={handleChangeProvince}
                  className="px-2 py-1 rounded text-sm border border-gray-300 outline-none focus:outline-none focus:shadow-outline w-full"
                >
                  {province.map((item, i) => (
                    <option key={i} value={item.Id}>
                      {item.Description}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-span-12 sm:col-span-6">
                <label
                  htmlFor="habitaciones"
                  className="block text-sm font-medium text-gray-700"
                >
                  Habitaciones
                </label>
                <div className="grid grid-cols-2">
                  <div className="col-span-6 sm:col-span-3">
                    <input
                      id="habitacionesMin"
                      name="habitacioneMin"
                      autoComplete="province-name"
                      placeholder="Min"
                      type="number"
                      onChange={(e) => setHabitacioneMin(e.target.value)}
                      value={habitacioneMin}
                      className="px-2 py-1 rounded text-sm border border-gray-300 outline-none focus:outline-none focus:shadow-outline w-full"
                    />
                  </div>
                </div>
              </div>
              <div className="col-span-12 sm:col-span-6">
                <label
                  htmlFor="habitaciones"
                  className="block text-sm font-medium text-transparent"
                >
                    Habitaciones
                </label>

                <div className="col-span-6 sm:col-span-3">
                  <input
                    id="habitacionesMax"
                    name="habitacioneMax"
                    placeholder="Máx"
                    type="number"
                    onChange={(e) => setHabitacioneMax(e.target.value)}
                    value={habitacioneMax}
                    className="px-2 py-1 rounded text-sm border border-gray-300 outline-none focus:outline-none focus:shadow-outline w-full"
                  />
                </div>
              </div>
              <div className="col-span-12 sm:col-span-6">
                <label
                  htmlFor="precios"
                  className="block text-sm font-medium text-gray-700"
                >
                  Precio
                </label>
                <div className="grid grid-cols-2">
                  <div className="col-span-6 sm:col-span-3">
                    <input
                      id="preciosMin"
                      name="preciosMin"
                      autoComplete="province-name"
                      placeholder="Min"
                      onChange={(e) => setPreciosMin(e.target.value)}
                      value={preciosMin}
                      type="number"
                      className="px-2 py-1 rounded text-sm border border-gray-300 outline-none focus:outline-none focus:shadow-outline w-full"
                    />
                  </div>
                </div>
              </div>
              <div className="col-span-12 sm:col-span-6">
                <label
                  htmlFor="precios"
                  className="block text-sm font-medium text-transparent"
                >
                  Precio
                </label>
                <div className="col-span-6 sm:col-span-3">
                  <input
                    id="preciosMax"
                    name="preciosMax"
                    autoComplete="province-name"
                    placeholder="Máx"
                    onChange={(e) => setPreciosMax(e.target.value)}
                    value={preciosMax}
                    type="number"
                    className="px-2 py-1 rounded text-sm border border-gray-300 outline-none focus:outline-none focus:shadow-outline w-full"
                  />
                </div>
              </div>
              <div className="col-span-12 sm:col-span-6">
                <label
                  htmlFor="tipoPropiedad"
                  className="block text-sm font-medium text-gray-700"
                >
                  Tipo Propiedad
                </label>
                <select
                  id="tipoPropiedad"
                  name="tipoPropiedad"
                  autoComplete="tipoPropiedad"
                  onChange={handleChangeProductType}
                  value={tipoPropiedad}
                  className="px-2 py-1 rounded text-sm border border-gray-300 outline-none focus:outline-none focus:shadow-outline w-full"
                >
                  {productType.map((item, i) => (
                    <option key={i} value={item.Id}>{item.Description}</option>
                  ))}
                </select>
              </div>
              <div className="bg-gray-50 px-4 py-3 text-right sm:px-12">
              
              <button
                type="Buscar"
                id="Buscar"
                name="tipoPropiedad"
                className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 mt-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                onClick={click_GetProductPublic}
              >
                Buscar
              </button>
            </div>
            </div>
           
          </div>
        {/* </div> */}
      </nav>
    </>
  );
}

export default SearchFields;
