import Inicio1034223 from "./Inicio1034223";

function Comp1034223() {
  return (
    <div className="max-w-4xl flex items-center h-auto lg:h-screen flex-wrap mx-auto my-32 lg:my-0">
      <div className="flex flex-wrap">
        <div className="w-full">
          <Inicio1034223 />
        </div>
      </div>
    </div>
  );
}

export default Comp1034223;
