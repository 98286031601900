import './Comp130749582.css'

function Comp130749582() {
  return (
    <div id="page-top">
      <header className="mastheadPena">
        <div className="container h-100">
          <div className="row h-100 align-items-center justify-content-center text-center">
            <div className="col-lg-10 align-self-end">
              <h1 className="text-uppercase text-white font-weight-bold">
                Hector M. Peña & Asoc. S.R.L., en una alianza estratégica con
                AdminSoftSystem.
              </h1>
              <hr className="divider my-4" />
            </div>
            <div className="col-lg-8 align-self-baseline">
              <p className="text-white-75 font-weight-light mb-5">
                Te puede ayudar a mejorar su proceso de contabilidad, venta,
                compra, cuenta por pagar y cobrar, mediante el uso de nuestra
                plataforma.
              </p>
              <a
                className="btn btn-primary btn-xl js-scroll-trigger mx-2 my-2"
                href="adminsoftcompany"
              >
                Registrar su empresa
              </a>
              <a
                className="btn btn-success btn-xl js-scroll-trigger  mx-2 my-2"
                href="http://hmpya.com/AdminSoftSystem/"
                target="_blank"
              >
                Entrar a sistema de envio
              </a>
              <a
                className="btn btn-danger btn-xl js-scroll-trigger  mx-2 my-2"
                href="newadminsoftsystem2020"
              >
                Entrar a nuestro sistema
              </a>
            </div>
          </div>
        </div>
      </header>
      <section className="page-section bg-dark" id="Acercadenosotros">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 text-center">
              <h2 className="text-white mt-0">
                ¡Aquí tu encuentra lo que tu empresa necesita!
              </h2>
              <hr className="divider light my-4" />
              <p className="text-white-50 mb-4">
                Nuestro equipo es un grupo de profesionales especializados en
                las áreas de la contabilidad , asuntos fiscales y en la
                ingeniería de sistemas con más de 25 y 18 años de experiencia en
                el mercado , acércate a nosotros y verás como te ayudamos.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="page-section" id="services">
        <div className="container">
          <h2 className="text-center mt-0">Nuestro Servicio</h2>
          <hr className="divider my-4" />
          <div className="row">
            <div className="col-lg-12 col-md-12 text-center">
              <div className="mt-5">
                <i className="fas fa-4x fa-gem text-primary mb-4"></i>
                <h3 className="h4 mb-2">
                  Asesoría fiscal, Auditoría, Contabilidad por Iguala
                </h3>
                <p className="text-muted mb-0">
                  Con, han desarrollado una herramienta que te permite al
                  contador público autorizado y que llevas múltiples igualas
                  para el llenado ágil, fácil de los formatos 606,607,608,609 y
                  623
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-12 text-center">
              <div className="mt-5">
                <i className="fas fa-4x fa-gem text-primary mb-4"></i>
                <h3 className="h4 mb-2">Llenado de 606,607,608,609 y 623</h3>
                <p className="text-muted mb-0">
                  Se a desarrollado una herramienta que te permite al contador
                  público autorizado y que llevas múltiples igualas para el
                  llenado ágil, fácil de los formulario 606,607,608,609 y 623
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 text-center">
              <div className="mt-5">
                <i className="fas fa-4x fa-laptop-code text-primary mb-4"></i>
                <h3 className="h4 mb-2">
                  Sistema de facturación, compra, cuenta por cobrar, cuenta por
                  pagar y contabilidad.
                </h3>
                <p className="text-muted mb-0">
                  Nuestro sistema de facturacion te permite trabajar de forma
                  rápida y precisa tu documento de facturacion, compra,
                  invetarios, ventas programada, cuenta por cobra, cuenta por
                  pagar y contabilidad.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="page-section" id="contact">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 text-center">
              <h2 className="mt-0">¡Mantengámonos en contacto!</h2>
              <hr className="divider my-4" />
              <p className="text-muted mb-5">
                ¿Listo para comenzar utilizar nuestro servicio? ¡Llámenos o
                envíenos un correo electrónico y nos pondremos en contacto con
                usted lo antes posible!
              </p>
              <p className="text-muted mb-4">
                Avenida Francia No.5. Edif. Hernandez Suite 2-8, Santiago,
                Repunblica Domincana
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 ml-auto text-center mb-5 mb-lg-0">
              <div>(809) 581-8516, (809) 581-0164, (809) 233-2323</div>
            </div>
            <div className="col-lg-4 mr-auto text-center">
              <a className="d-block" href="mailto:hectorpena16@hotmail.com">
                hectorpena16@hotmail.com
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 ml-auto text-center mb-5 mb-lg-0">
              <div>(809) 877-3696</div>
            </div>
            <div className="col-lg-4 mr-auto text-center">
              <a className="d-block" href="mailto:medina_juan82@hotmail.com">
                medina_juan82@hotmail.com
              </a>
            </div>
          </div>
        </div>
      </section>

      <footer className="bg-light py-5">
        <div className="container">
          <div className="small text-center text-muted">
            Copyright &copy; 2020 - Start Bootstrap and AdminSoftSystem
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Comp130749582;
