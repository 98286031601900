import React, { useState } from 'react';

import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import AgendaModal from './AgendaModal';

const localizer = momentLocalizer(moment)

function Citas({closeModal}){
  const [abrirModalAgenda,setAbrirModalAgenda] = useState(false);
  const [agendarCita, setAgendarCita] = useState([]);
  const [citaSeleccionada, setCitaSeleccionada] = useState({});
  const closeAgendarCita = () =>{
    setAbrirModalAgenda(false);
  }
  const abriAgendarCita = (evento) =>{
    // console.log(evento)
    setCitaSeleccionada({id: agendarCita.length,
      title:"Prueba dando click"+agendarCita.length,
      allDay:false,
      start:evento.start,
      end:evento.end});
    setAgendarCita(cita => [...cita,citaSeleccionada]);
    setAbrirModalAgenda(true);
    console.log(citaSeleccionada);
  }
    return (
      <div>
        <div className="justify-center  items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative w-auto my-6 mx-auto max-w-full max-h-full">
            {/*content*/}
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              {/*header*/}
              <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                {/* <h3 className="text-3xl font-semibold">{item.Name}</h3> */}
                <button
                  className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                  onClick={() => closeModal()}
                >
                  <span className="bg-transparent text-red-500 opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                    ×
                  </span>
                </button>
              </div>
              <Calendar
                  localizer={localizer}
                  events={agendarCita}
                  startAccessor='start'
                  endAccessor='end'
                  style={{ height: 500 }}
                  onSelectSlot={(diaSeleccionado) => {
                      // console.log(slotInfo)
                      abriAgendarCita(diaSeleccionado);
                  }}
                  selectable
                  popup={true}
              />
         
              <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                <button
                  className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => closeModal()}
                >
                  Cerrar
                </button>

              </div>
            </div>
          </div>
        </div>

        {abrirModalAgenda ? <AgendaModal closeModal={closeAgendarCita} datosCita={citaSeleccionada} /> : null}
      </div>
    );
  
}

export default Citas;