import { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { StateContext } from "../../provider/StateProvider";
import ProductCategory from "../productCategory/ProductCategory";

export default function Navbar({ fixed }) {
  const [navbar, setNavbar] = useState(false);
  const { company, user, handleAuthenticaton } = useContext(StateContext);

  return (
    <>
      <nav className="w-full bg-cyan-900 shadow">
        <div className="justify-between mx-auto px-4 lg:max-w-9xl md:items-center md:flex md:px-8 mt-3">
          <div>
            <div className="flex items-center justify-between py-3 md:py-5 md:block">
              <Link to="/">
                <div className="flex-shrink-0 flex items-center">
                  <img
                    className="block lg:hidden h-8 w-auto"
                    src={company?.Photo?.Photo}
                    alt={company?.Name}
                  />
                  <img
                    className="hidden lg:block h-8 w-auto"
                    src={company?.Photo?.Photo}
                    alt={company?.Name}
                  />
                  <samp className="hidden lg:block h-8 w-auto text-white px-3   text-2xl font-medium">
                    {company?.Name}
                  </samp>
                  <samp className="block lg:hidden h-6 w-auto text-white px-1   text-1xl font-medium">
                    {company?.Name}
                  </samp>
                </div>
              </Link>
              <div className="md:hidden">
                <button
                  className="p-2 text-gray-700 rounded-md outline-none focus:border-gray-400 focus:border"
                  onClick={() => setNavbar(!navbar)}
                >
                  {navbar ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6 text-white"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6 text-white"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M4 6h16M4 12h16M4 18h16"
                      />
                    </svg>
                  )}
                </button>
              </div>
            </div>
          </div>
          <div>
            <div
              className={`flex-1 justify-self-center pb-3 mt-8 md:block md:pb-0 md:mt-0 ${
                navbar ? "block" : "hidden"
              }`}
            >
              <ProductCategory />

              <div className="mt-3 space-y-2 lg:hidden md:inline-block">
                {user === null ? (
                  <div>
                    {" "}
                    <a
                      href={"/login"}
                      className="inline-block w-full px-4 py-2 text-center text-white bg-gray-600 rounded-md shadow hover:bg-gray-800"
                    >
                      Iniciar sesión
                    </a>
                    <a
                      href={"/register"}
                      className="inline-block w-full px-4 py-2 text-center text-gray-800 bg-white rounded-md shadow hover:bg-gray-100"
                    >
                      Regístrate
                    </a>{" "}
                  </div>
                ) : (
                  <div>
                    <button
                      onClick={() => {
                        handleAuthenticaton();
                      }}
                      className="inline-block w-full px-4 py-2 text-center text-gray-800 bg-white rounded-md shadow hover:bg-gray-100"
                    >
                      Cerrar sesión
                    </button>
                    <div className="inline-block w-full py-2 ">
                      <div className="inset-y-0 right-0 flex items-center pr-2 ">
                        <button
                          type="button"
                          className="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                          id="user-menu-button"
                          aria-expanded="false"
                          aria-haspopup="true"
                        >
                          {user?.Photo?.Photo === "" ? (
                            <div className="h-10 w-10 rounded-full text-blue-50  bg-slate-400 flex items-center  ">
                              <label className="ml-1.5 italic text-2xl cursor-pointer">
                                {" "}
                                {user?.Name.substring(0, 1) +
                                  user?.LastName.substring(0, 1)}{" "}
                              </label>
                            </div>
                          ) : (
                            <img
                              className="h-10 w-10 rounded-full"
                              src={user?.Photo?.Photo}
                              alt=""
                            />
                          )}
                        </button>
                        <div className="absolute right-0 pr-5 text-blue-50 ml-1.5 italic text-2xl cursor-pointer">
                          {user?.Name + " " + user?.LastName}{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="hidden space-x-2 md:inline-block">
            {user === null ? (
              <div>
                <a
                  href={"/login"}
                  className="px-4 py-2 text-white bg-gray-600 rounded-md shadow hover:bg-gray-800"
                >
                  Iniciar sesión
                </a>
                <a
                  href={"/register"}
                  className="px-4 py-2 text-gray-800 bg-white rounded-md shadow hover:bg-gray-100"
                >
                  Regístrate
                </a>
              </div>
            ) : (
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                <button
                  onClick={() => {
                    handleAuthenticaton();
                  }}
                  className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2   rounded-md  shadow"
                  tabIndex="-1"
                >
                  Cerrar sesión
                </button>
                <div className="relative">
                  <div>
                    <button
                      type="button"
                      className="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                      id="user-menu-button"
                      aria-expanded="false"
                      aria-haspopup="true"
                    >
                      {user?.Photo?.Photo === "" ? (
                        <div className="h-10 w-10 rounded-full text-blue-50  bg-slate-400 flex items-center  ">
                          <label className="ml-1.5 italic text-2xl cursor-pointer">
                            {" "}
                            {user?.Name.substring(0, 1) +
                              user?.LastName.substring(0, 1)}{" "}
                          </label>
                        </div>
                      ) : (
                        <img
                          className="h-10 w-10 rounded-full"
                          src={user?.Photo?.Photo}
                          alt=""
                        />
                      )}
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </nav>
    </>
  );
}
