import { useState, useEffect } from "react";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";

function AddProductModal({ item, urlImg, closeModal }) {
  const [images, setImages] = useState([]);
  const addImg = () => {
    let _return =[];
    if (item.PhotoProduct.length > 0) {
      item.PhotoProduct.map((itemImag, i) => {
        _return.push(
          {
            original: urlImg + itemImag._id,
            thumbnail: urlImg + itemImag._id,
          });
      });
    }
    return _return
  };
  useEffect(() => {
    setImages(addImg());
  }, []);

  return (
    
    <div>
      <div className="justify-center  items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-full max-h-full">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-3xl font-semibold">{item.Name}</h3>
              <button
                className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={() => closeModal()}
              >
                <span className="bg-transparent text-red-500 opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                  ×
                </span>
              </button>
            </div>

            {/*body*/}
            <div className="relative  flex-auto px-5 pb-5 ">
              <div
                id="default-carousel"
                className="relative"
                data-carousel="static"
              >
                <div className="relative h-90 overflow-hidden rounded-lg md:h-96">
                  {images ? <ImageGallery items={images} /> : <div />}
                </div>
              </div>
              <div className="overflow-y-scroll mt-2.5 mb-15">
                <div
                  className="h-48 "
                  dangerouslySetInnerHTML={{ __html: item.Description }}
                />
              </div>
            </div>
            {/*footer*/}
            <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
              <button
                className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => closeModal()}
              >
                Cerrar
              </button>
            
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </div>
  );
}

export default AddProductModal;
