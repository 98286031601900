import { useState, useContext, useRef } from "react";
import { StateContext } from "../../provider/StateProvider";
import axios from "axios";
import "./Contacto1034223.css";

const apiInsertUserWeb = axios.create({
  baseURL: `http://108.181.162.121:3617/adminSoftSystem/api/sendEmailAdminSoftSystem/`,
  //baseURL: `http://108.181.162.121:3617/adminSoftSystem/api/sendEmailAdminSoftSystem/`,
});

function Contacto1034223() {
  const { company, alertMsg, loadIndicator, load } = useContext(StateContext);

  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const refFullName = useRef(null);
  const refEmail = useRef(null);
  const refSubject = useRef(null);
  const refMessage = useRef(null);

  const sendEmailAdminSoftSystem = (e) => {
    e.preventDefault();
    loadIndicator(true);
    if (load) return;
    if (fullName === "") {
      alertMsg("Error, la nombre completo es requerido", refFullName);
      loadIndicator(false);
      return;
    } else if (email === "") {
      alertMsg(
        "Error, la dirección de correo electrónico es requerida",
        refEmail
      );
      loadIndicator(false);
      return;
    } else if (subject === "") {
      alertMsg(
        "Error, el asunto del correo electrónico es requerida",
        refSubject
      );
      loadIndicator(false);
      return;
    } else if (message === "") {
      alertMsg("Error, el mensaje es requerido", refMessage);
      loadIndicator(false);
      return;
    }

    let data = {
      to: [
        {
          email_address: {
            address: "medina_juan82@hotmail.com",
            name: "Juan Medina",
          },
        },
        {
          email_address: {
            address: "lenny1053690@hotmail.com",
            name: "Lenny Valdez",
          },
        },
        {
          email_address: {
            address: "yanerysgarcia@hotmail.com",
            name: "Yanerys Garcia",
          },
        },
        {
          email_address: {
            address: email,
            name: fullName,
          },
        },
      ],
      subject: subject,
      htmlbody: message,
    };

    let config = {
      headers: {
        Authorization: "Bearer " + company?.AccessToken,
      },
    };

    apiInsertUserWeb
      .post("/", JSON.stringify(data), config)
      .then((res) => {
        if (
          res?.data?.DESCRIPTION_ERROR === undefined ||
          res?.data?.DESCRIPTION_ERROR === null ||
          res?.data?.DESCRIPTION_ERROR === ""
        ) {
          alertMsg(
            "Su correo fue satisfactoriamente enviado",
            null,
            "Gracia",
            "S"
          );
        } else {
          alertMsg(res?.data?.DESCRIPTION_ERROR);
        }

        setFullName("");
        setEmail("");
        setSubject("");
        setMessage("");
        loadIndicator(false);
      })
      .catch((err) => {
        if (
          (err?.data?.DESCRIPTION_ERROR !== undefined &&
            err?.data?.DESCRIPTION_ERROR !== null) ||
          err?.data?.DESCRIPTION_ERROR !== ""
        ) {
          alertMsg(err?.data?.DESCRIPTION_ERROR);
        } else {
          alertMsg(err);
        }
        loadIndicator(false);
      });
  };
  return (
    <section Name="section gray-bg" id="Contacto">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="section-title">
              <h2>Adminsoftsystem</h2>
              <p>
                Diseño y desarrollo servicios para clientes de todos los
                tamaños, especializándome en la creación de sitios web modernos
                y elegantes.
              </p>
            </div>
          </div>
        </div>
        <div className="row flex-row-reverse">
          <div className="col-md-7 col-lg-8 m-15px-tb">
            <div className="contact-form">
              <form onSubmit={sendEmailAdminSoftSystem}
                className="contactform contact_form"
                id="contact_form"
              >
                <div
                  className="returnmessage valid-feedback p-15px-b"
                  data-success="Your message has been received, We will contact you soon."
                ></div>
                <div className="empty_notice invalid-feedback p-15px-b">
                  <span>Please Fill Required Fields</span>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        id="fullName"
                        type="text"
                        placeholder="Nombre completo"
                        className="form-control"
                        required=""
                        autoFocus
                        value={fullName}
                        onChange={(e) => setFullName(e.target.value)}
                        ref={refFullName}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        id="email"
                        type="text"
                        placeholder="Dirección de correo electrónico"
                        className="form-control"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        ref={refEmail}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <input
                        id="subject"
                        type="text"
                        placeholder="Asunto del correo electrónico"
                        className="form-control"
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                        ref={refSubject}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <textarea
                        id="message"
                        placeholder="Mensaje"
                        className="form-control"
                        rows="3"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        ref={refMessage}
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="send" >
                      <button id="send_message" className="px-btn theme"  onClick={(e) => sendEmailAdminSoftSystem(e)}>
                        <span>Enviar mensaje</span> <i className="arrow"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-md-5 col-lg-4 m-15px-tb">
            <div className="contact-name">
              <h5>Correo</h5>
              <p>
                <a href="mailto:medina_juan82@hotmail.com">
                  medina_juan82@hotmail.com
                </a>
              </p>
            </div>
            <div className="contact-name">
              <h5>Visita mi estudio</h5>
              <p>
                Avenida Nuñez de Caceres, <br />
                Santiago, Republica Dominicana
              </p>
            </div>
            <div className="contact-name">
              <h5>Teléfono</h5>
              <p>809-877-3696</p>
            </div>
            <div className="contact-name">
              <h5>Whatsapp</h5>
              <p>
                <a
                  href="https://api.whatsapp.com/send?phone=8098773696&text=Hola,%20es%20entrado%20a%20tu%20pagina%20y%20me%20interesa%20ver%20tu%20servicio"
                  target="_blank"
                >
                  809-877-3696
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contacto1034223;
