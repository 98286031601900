import { useState } from "react";
import { Link } from "react-router-dom";
import martecleaning from "../../img/martecleaning.png";

function NavbarMartecleaning() {
  const [navbar, setNavbar] = useState(false);
  return (
    <>
    
      <nav className="w-full bg-transparent shadow">
        <div className="justify-between mx-auto px-4 lg:max-w-9xl md:items-center md:flex md:px-8 m-3">
          <div>
            <div className="flex items-center justify-between py-3 md:py-5 md:block">
              <Link to="/">
                <div className="flex-shrink-0 flex items-center">
                  <samp className="hidden lg:block h-8 w-auto text-white px-3   text-2xl font-medium">
                    <img
                      src={martecleaning}
                      className="object-cover w-90 h-8 "
                      alt="MarteCleaning"
                    />
                  </samp>
                  <samp className="block lg:hidden h-6 w-auto text-white px-1   text-1xl font-medium">
                    <img
                      src={martecleaning}
                      className="object-cover  h-6 "
                      alt="MarteCleaning"
                    />
                  </samp>
                </div>
              </Link>
              <div className="md:hidden">
                <button
                  className="p-2 text-gray-700 rounded-md outline-none focus:border-gray-400 focus:border"
                  onClick={() => setNavbar(!navbar)}
                >
                  {navbar ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6 text-blue"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6 text-blue"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M4 6h16M4 12h16M4 18h16"
                      />
                    </svg>
                  )}
                </button>
              </div>
            </div>
          </div>
          <div>
            <div
              className={`flex-1 justify-self-center pb-0 pt-2 mt-8 md:block md:pb-0 md:mt-0 ${
                navbar ? "block" : "hidden"
              }`}
            >
              <div>
                <ul className="items-center justify-center space-y-8 md:flex md:space-x-6 md:space-y-0">
                  <li className="text-white hover:text-indigo-200">
                    <a
                      href="/#About"
                      className="text-blue-50 hover:bg-gray-700 hover:text-white px-3 py-2   rounded-md text-sm font-medium"
                    >
                      About
                    </a>
                  </li>
                  <li className="text-white hover:text-indigo-200">
                    <a
                      href="/#Services"
                      className="text-blue-50 hover:bg-gray-700 hover:text-white px-3 py-2   rounded-md text-sm font-medium"
                    >
                      Services
                    </a>
                  </li>
                  <li className="text-white hover:text-indigo-200">
                    <a
                      href="/#Pricing"
                      className="text-blue-50 hover:bg-gray-700 hover:text-white px-3 py-2   rounded-md text-sm font-medium"
                    >
                      Pricing
                    </a>
                  </li>
                  <li className="text-white hover:text-indigo-200">
                    <a
                      href="/#Contact"
                      className="text-blue-50 hover:bg-gray-700 hover:text-white px-3 py-2   rounded-md text-sm font-medium"
                    >
                      Contact
                    </a>
                  </li>
                </ul>
              </div>
              
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}

export default NavbarMartecleaning;
