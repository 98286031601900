import imgHome from "../../img/bg_marte_01.jpg";
import imgAbout from "../../img/bg_marte_02.jpg";
import imgServices from "../../img/bg_marte_03.jpg";
import "./magnific-popup.css";
import "./style.css";
import "./CompMartecleaning.css";
import "./animate.css";

function CompMartecleaning() {
  const imgHomeUrl = imgHome;
  const imgAboutUrl = imgAbout;
  const imgServicesUrl = imgServices;
  return (
    <div>
      <section className="d-flex align-items-stretch">
        <img
          className="relative w-full h-900 xs:absolute p-2"
          src={imgHomeUrl}
          alt=""
        />
      </section>
      <hr className="mb-4 " />
      <section id="About">
        <img
          className="relative w-100 h-100 xs:absolute p-2"
          src={imgAboutUrl}
          alt=""
        />
      </section>
      <hr className="mb-4 " />
      <section className="heading-section text-center " id="Services">
        <img
          className="relative w-full h-900 xs:absolute p-2"
          src={imgServicesUrl}
          alt=""
        />
      </section>

      <section className="ftco-section bg-light" id="Pricing">
        <div className="container">
          <div className="row justify-content-center pb-5 mb-3">
            <div className="col-md-7 heading-section text-center ">
              <span className="subheading mb-3">Price &amp; Plans</span>
              <h2>Choose Your Perfect Plans</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-lg-3 ">
              <div className="block-7">
                <div className="text-center">
                  <div className="icon d-flex align-items-center justify-content-center">
                    <span className="fa flaticon-sprayer"></span>
                  </div>
                  <h4 className="heading-2">Starter</h4>
                  <span className="price">
                    <sup>$</sup> <span className="number">49</span>
                  </span>

                  <ul className="pricing-text mb-5">
                    <li>
                      <span className="fa fa-check mr-2"></span>Bedrooms
                      cleaning
                    </li>
                    <li>
                      <span className="fa fa-check mr-2"></span>Vacuuming
                    </li>
                    <li>
                      <span className="fa fa-check mr-2"></span>Bathroom
                      cleaning
                    </li>
                    <li>
                      <span className="fa fa-check mr-2"></span>Mirrow cleaning
                    </li>
                    <li>
                      <span className="fa fa-check mr-2"></span>Livingroom
                      cleaning
                    </li>
                  </ul>

                  <a href="#" className="btn btn-primary px-4 py-3">
                    Get Started
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 ">
              <div className="block-7 ">
                <div className="text-center">
                  <div className="icon d-flex align-items-center justify-content-center">
                    <span className="fa flaticon-vacuum-cleaner"></span>
                  </div>
                  <h4 className="heading-2">Standard</h4>
                  <span className="price">
                    <sup>$</sup> <span className="number">79</span>
                  </span>

                  <ul className="pricing-text mb-5">
                    <li>
                      <span className="fa fa-check mr-2"></span>Bedrooms
                      cleaning
                    </li>
                    <li>
                      <span className="fa fa-check mr-2"></span>Vacuuming
                    </li>
                    <li>
                      <span className="fa fa-check mr-2"></span>Bathroom
                      cleaning
                    </li>
                    <li>
                      <span className="fa fa-check mr-2"></span>Mirrow cleaning
                    </li>
                    <li>
                      <span className="fa fa-check mr-2"></span>Livingroom
                      cleaning
                    </li>
                  </ul>

                  <a href="#" className="btn btn-primary px-4 py-3">
                    Get Started
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="block-7">
                <div className="text-center">
                  <div className="icon d-flex align-items-center justify-content-center">
                    <span className="fa flaticon-tap"></span>
                  </div>
                  <h4 className="heading-2">Premium</h4>
                  <span className="price">
                    <sup>$</sup> <span className="number">109</span>
                  </span>

                  <ul className="pricing-text mb-5">
                    <li>
                      <span className="fa fa-check mr-2"></span>Bedrooms
                      cleaning
                    </li>
                    <li>
                      <span className="fa fa-check mr-2"></span>Vacuuming
                    </li>
                    <li>
                      <span className="fa fa-check mr-2"></span>Bathroom
                      cleaning
                    </li>
                    <li>
                      <span className="fa fa-check mr-2"></span>Mirrow cleaning
                    </li>
                    <li>
                      <span className="fa fa-check mr-2"></span>Livingroom
                      cleaning
                    </li>
                  </ul>

                  <a href="#" className="btn btn-primary px-4 py-3">
                    Get Started
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="block-7">
                <div className="text-center">
                  <div className="icon d-flex align-items-center justify-content-center">
                    <span className="fa flaticon-cleaning"></span>
                  </div>
                  <h4 className="heading-2">Platinum</h4>
                  <span className="price">
                    <sup>$</sup> <span className="number">159</span>
                  </span>

                  <ul className="pricing-text mb-5">
                    <li>
                      <span className="fa fa-check mr-2"></span>Bedrooms
                      cleaning
                    </li>
                    <li>
                      <span className="fa fa-check mr-2"></span>Vacuuming
                    </li>
                    <li>
                      <span className="fa fa-check mr-2"></span>Bathroom
                      cleaning
                    </li>
                    <li>
                      <span className="fa fa-check mr-2"></span>Mirrow cleaning
                    </li>
                    <li>
                      <span className="fa fa-check mr-2"></span>Livingroom
                      cleaning
                    </li>
                  </ul>

                  <a href="#" className="btn btn-primary px-4 py-3">
                    Get Started
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="page-section" id="Contact">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 text-center">
              <h2 className="mt-0">Contact</h2>
              <hr />
            </div>

            <div class="row justify-content-center">
              <div class="col-md-12">
                <div class="wrapper">
                  <div class="row mb-5">
                    <div class="col-md-3">
                      <div class="dbox w-100 text-center">
                        <div class="icon d-flex align-items-center justify-content-center">
                          <span class="fa fa-map-marker"></span>
                        </div>
                        <div class="text">
                          <p>
                            <span>Address:</span> 561 Cleveland Street,
                            Hazleton, PA 18201
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="dbox w-100 text-center">
                        <div class="icon d-flex align-items-center justify-content-center">
                          <span class="fa fa-phone"></span>
                        </div>
                        <div class="text">
                          <p>
                            <span>Phone:</span>{" "}
                            <a href="tel://1(570) 401-4889">
                              + 1(570) 401-4889
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="dbox w-100 text-center">
                        <div class="icon d-flex align-items-center justify-content-center">
                          <span class="fa fa-paper-plane"></span>
                        </div>
                        <div class="text">
                          <p>
                            <span>Email:</span>{" "}
                            <a href="mailto:info@martecleaning.com">
                              info@martecleaning.com
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="dbox w-100 text-center">
                        <div class="icon d-flex align-items-center justify-content-center">
                          <span class="fa fa-globe"></span>
                        </div>
                        <div class="text">
                          <p>
                            <span>Website</span>{" "}
                            <a href="#">www.martecleaning.com</a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row no-gutters">
                    <div class="col-md-7">
                      <div class="contact-wrap w-100 p-md-5 p-4">
                        <h3 class="mb-4">Contact Us</h3>
                        <div id="form-message-warning" class="mb-4"></div>
                        <div id="form-message-success" class="mb-4">
                          Your message was sent, thank you!
                        </div>
                        <form
                          method="POST"
                          id="contactForm"
                          name="contactForm"
                          class="contactForm"
                        >
                          <div class="row">
                            <div class="col-md-6">
                              <div class="form-group">
                                <label class="label" for="name">
                                  Full Name
                                </label>
                                <input
                                  type="text"
                                  class="form-control"
                                  name="name"
                                  id="name"
                                  placeholder="Name"
                                />
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <label class="label" for="email">
                                  Email Address
                                </label>
                                <input
                                  type="email"
                                  class="form-control"
                                  name="email"
                                  id="email"
                                  placeholder="Email"
                                />
                              </div>
                            </div>
                            <div class="col-md-12">
                              <div class="form-group">
                                <label class="label" for="subject">
                                  Subject
                                </label>
                                <input
                                  type="text"
                                  class="form-control"
                                  name="subject"
                                  id="subject"
                                  placeholder="Subject"
                                />
                              </div>
                            </div>
                            <div class="col-md-12">
                              <div class="form-group">
                                <label class="label" for="#">
                                  Message
                                </label>
                                <textarea
                                  name="message"
                                  class="form-control"
                                  id="message"
                                  cols="30"
                                  rows="4"
                                  placeholder="Message"
                                ></textarea>
                              </div>
                            </div>
                            <div class="col-md-12">
                              <div class="form-group">
                                <input
                                  type="submit"
                                  value="Send Message"
                                  class="btn btn-primary"
                                />
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <footer className="bg-light py-5">
        <div className="container">
          <div className="small text-center text-muted">
            Copyright &copy; 2023 -{" "}
            <a href="www.adminSoftSystems.com">www.adminSoftSystems.com</a>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default CompMartecleaning;
