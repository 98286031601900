import { useEffect, useContext, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./component/login/Login";
import ViewProduct from "./component/viewProduct/ViewProduct";
import { StateContext } from "./provider/StateProvider";
import Register from "./component/register/Register";
import Indicators from "./component/indicators/Indicators";
import Navbar from "./component/navbar/Navbar";
import Comp03104683663 from "./component/comp03104683663/Comp03104683663";
import Comp1034223 from "./component/comp1034223/Comp1034223";
import ViewProductDetail from "./component/viewProductDetail/ViewProductDetail";
import Navbar1034223 from "./component/comp1034223/Navbar1034223";
import Servicios1034223 from "./component/comp1034223/Servicios1034223";
import SobreNosotros1034223 from "./component/comp1034223/SobreNosotros1034223";
import Contacto1034223 from "./component/comp1034223/Contacto1034223";
import Home from "./component/home/Home";
import Comp130749582 from "./component/comp130749582/Comp130749582";
import Nab130749582 from "./component/comp130749582/Nab130749582";
import Page from "./component/compMartecleaning/Page";

function App() {
  const [productCategory, setProductCategory] = useState([]);
  const {
    load,
    loadIndicator,
    updateUserData,
    updateCompanyData,
    addProductCategory,
    addProductType,
    addProvince,
    apiRegistry,
    apiInventory,
    apiMaintenance,
  } = useContext(StateContext);

  let link =
    document.querySelector('link[rel="shortcut icon"]') ||
    document.querySelector('link[rel="icon"]');

  if (!link) {
    link = document.createElement("link");
    link.id = "favicon";
    link.rel = "shortcut icon";
    document.head.appendChild(link);
  }

  link.href = `adminsoftsystem.ico`;

  const MyLocationHostname = window.location.hostname.trim().toUpperCase();
  let companyId = "1034223"; //"1034223";//"MC000000001"; //"03104683663";//  ////// "K0000000001" ;

  if (
    MyLocationHostname === "WWW.INMOBILIARIADREAMHOUSE.COM" ||
    MyLocationHostname === "INMOBILIARIADREAMHOUSE.COM"
  ) {
    companyId = "03104683663";
    link.href = `favicon.ico`;
  } else if (
    MyLocationHostname === "WWW.HMPYA.COM" ||
    MyLocationHostname === "HMPYA.COM"
  ) {
    companyId = "130749582";
    link.href = `hmpya.ico`;
  } else if (
    MyLocationHostname === "WWW.MARTECLEANING.COM" ||
    MyLocationHostname === "MARTECLEANING.COM"
  ) {
    companyId = "MC000000001";
    link.href = `martecleaning.ico`;
  }

  useEffect(() => {
    loadIndicator(true);
    updateUserData();

    apiRegistry.post("/getSystemCompany/", { ID: companyId }).then((res) => {
      document.title = res.data.Data.Name;
      let TypeId = res.data.Data.TypeId;
      updateCompanyData(res.data.Data);
      let config = {
        headers: {
          Authorization: "Bearer " + res.data.Data.AccessToken,
        },
      };
      apiInventory.post("/getProductCategory/", {}, config).then((res) => {
        addProductCategory(res.data.Data);
        setProductCategory(res.data.Data);
        loadIndicator(false);
        if (TypeId === "04") {
          loadIndicator(true);
          apiInventory.post("/getProductType/", {}, config).then((res) => {
            addProductType(res.data.Data);
            apiMaintenance
              .post(
                "/getMaintenance/",
                {
                  CompanyId: companyId,
                  TypeUsersId: "01",
                  TableName: "PROVINCE",
                  Description: "",
                  IdSec: "",
                },
                config
              )
              .then((res) => {
                addProvince(res.data.Data);
                loadIndicator(false);
              });
          });
        }
      });
    });
  }, []);

  return (
    <div className="w-full h-full " style={{ marginTop: "-20px" }}>
      <Router>
        {companyId === "1034223" ? (
          <Navbar1034223 />
        ) : companyId === "130749582" ? (
          <Nab130749582 />
        ) : companyId === "MC000000001" ? (
          <Page></Page>
        ) : (
          <Navbar />
        )}
        {companyId === "1034223" ? (
          <Routes>
            <Route path="/" element={<Comp1034223 />} />
            <Route path="/Servicios" element={<Servicios1034223 />} />
            <Route path="/SobreNosotros" element={<SobreNosotros1034223 />} />
            <Route path="/Contacto" element={<Contacto1034223/>} />
            
          </Routes>
        ) : companyId === "130749582" ? (
          <Comp130749582 />
        ) : companyId === "MC000000001" ? (
          ""
        ) : (
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route
              path="/"
              element={
                companyId === "03104683663" ? <Comp03104683663 /> : <Home />
              }
            />
            {productCategory.map((item, i) => (
              <Route
                path={
                  "/" +
                  item?.Description.replace(" ", "")
                    .replace(" ", "")
                    .replace(" ", "")
                }
                key={i}
                element={<ViewProduct ProductCategoryId={item.Id} />}
              />
            ))}
            <Route
              path="/viewProductDetail/:companyId/:id"
              element={<ViewProductDetail />}
            />
          </Routes>
        )}
      </Router>
      {load ? <Indicators /> : null}
    </div>
  );
}
export default App;
