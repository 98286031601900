import Card1034223 from "./Card1034223";
import "./SobreNosotros1034223.css";
function Servicios1034223() {
  return (
    <section id="services" className="services section-bg">
      <div className="container">
        <header className="section-header">
          <h3>Servicios</h3>
          <p>
            A continuación, presentamos algunos de los servicios que tenemos en
            operación, con el desarrollo de nuestro sistema en la nube con
            aplicaciones de escritorio y móviles.
          </p>
        </header>

        <section className="">
          <div className="container mx-auto px-5 py-10">
            <div className="-m-4 flex flex-wrap">
              <Card1034223
                href="/AdminSoftSystem/"
                titule="Llenado 606,607.."
                description="Envío de archivo a la dirección general de impuestos,606,607,608,609 y 623"
                path1="M5 5a3 3 0 015-2.236A3 3 0 0114.83 6H16a2 2 0 110 4h-5V9a1 1 0 10-2 0v1H4a2 2 0 110-4h1.17C5.06 5.687 5 5.35 5 5zm4 1V5a1 1 0 10-1 1h1zm3 0a1 1 0 10-1-1v1h1z"
                path2="M9 11H3v5a2 2 0 002 2h4v-7zM11 18h4a2 2 0 002-2v-5h-6v7z"
              />
              <Card1034223
                href="/newadminsoftsystem2020/"
                titule="Sistema web"
                description="Compra, facturación, cuentas por pagar, cobrar y envío de correo a suplidores y clientes."
                path1="M5 5a3 3 0 015-2.236A3 3 0 0114.83 6H16a2 2 0 110 4h-5V9a1 1 0 10-2 0v1H4a2 2 0 110-4h1.17C5.06 5.687 5 5.35 5 5zm4 1V5a1 1 0 10-1 1h1zm3 0a1 1 0 10-1-1v1h1z"
                path2="M9 11H3v5a2 2 0 002 2h4v-7zM11 18h4a2 2 0 002-2v-5h-6v7z"
              />
              <Card1034223
                href="/CompAdminSoftSystem/setup.exe"
                titule="Factura rapida"
                description="Este sistema permite procesos rápidos de facturación con impresión instantánea."
                path1="M5 5a3 3 0 015-2.236A3 3 0 0114.83 6H16a2 2 0 110 4h-5V9a1 1 0 10-2 0v1H4a2 2 0 110-4h1.17C5.06 5.687 5 5.35 5 5zm4 1V5a1 1 0 10-1 1h1zm3 0a1 1 0 10-1-1v1h1z"
                path2="M9 11H3v5a2 2 0 002 2h4v-7zM11 18h4a2 2 0 002-2v-5h-6v7z"
              />
              <Card1034223
                href="/ELASS/"
                titule="ELASS"
                description="ELASS es una herramienta de estadísticas. la cual se puede visualiza a trave de un movil"
                path1="M5 5a3 3 0 015-2.236A3 3 0 0114.83 6H16a2 2 0 110 4h-5V9a1 1 0 10-2 0v1H4a2 2 0 110-4h1.17C5.06 5.687 5 5.35 5 5zm4 1V5a1 1 0 10-1 1h1zm3 0a1 1 0 10-1-1v1h1z"
                path2="M9 11H3v5a2 2 0 002 2h4v-7zM11 18h4a2 2 0 002-2v-5h-6v7z"
              />

              <Card1034223
                href="/Utilities/"
                titule="Utilitarios"
                description="Herramienta para subir los archivos de múltiples empresas y pasarlo a nuestro sistema de envío."
                path1="M5 5a3 3 0 015-2.236A3 3 0 0114.83 6H16a2 2 0 110 4h-5V9a1 1 0 10-2 0v1H4a2 2 0 110-4h1.17C5.06 5.687 5 5.35 5 5zm4 1V5a1 1 0 10-1 1h1zm3 0a1 1 0 10-1-1v1h1z"
                path2="M9 11H3v5a2 2 0 002 2h4v-7zM11 18h4a2 2 0 002-2v-5h-6v7z"
              />
            </div>
          </div>
        </section>
      </div>
    </section>
  );
}

export default Servicios1034223;
