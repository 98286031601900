import { useEffect, useContext, useState } from "react";
import { StateContext } from "../../provider/StateProvider";
import AddProduct from "../addProduct/AddProduct";
import axios from "axios";
import SearchFields from "../searchField/SearchFields";

const apiInventory = axios.create({
  baseURL: `http://108.181.162.121:3608/adminSoftSystem/api/`,
});

function ViewProduct({ ProductCategoryId }) {
  const { company } = useContext(StateContext);
  const [product, setProduct] = useState([]);

  const getProductPublic = (
    Id,
    ProductTypeId,
    ProductGroupId,
    ProductRatingId,
    MinPrice,
    MaxPrice,
    MinRoom,
    MaxRoom,
    MinBuilding,
    MaxBuilding,
    Condition,
    CountryId,
    MunicipalityId,
    ProvinceId
  ) => {

    let config = {
      headers: {
        Authorization: "Bearer " + company?.AccessToken,
      },
    };
    apiInventory
      .post(
        "/getProductPublic/",
        JSON.stringify({
          Id: Id === undefined ? "" : Id,
          Name: "",
          ProductCategoryId:
          ProductCategoryId === undefined ? "" : ProductCategoryId,
          ProductTypeId: ProductTypeId === undefined ? "" : ProductTypeId,
          ProductGroupId: ProductGroupId === undefined ? "" : ProductGroupId,
          ProductRatingId: ProductRatingId === undefined ? "" : ProductRatingId,
          MinPrice: MinPrice === undefined ? 0 : MinPrice,
          MaxPrice: MaxPrice === undefined ? 0 : MaxPrice,
          MinRoom: MinRoom === undefined ? 0 : MinRoom,
          MaxRoom: MaxRoom === undefined ? 0 : MaxRoom,
          MinBuilding: MinBuilding === undefined ? 0 : MinBuilding,
          MaxBuilding: MaxBuilding === undefined ? 0 : MaxBuilding,
          Condition: Condition === undefined ? 0 : Condition,
          MunicipalityId: MunicipalityId === undefined ? "" : MunicipalityId,
          ProvinceId: ProvinceId === undefined ? "" : ProvinceId,
          CountryId: CountryId === undefined ? "" : CountryId,
          NumberPage: 1,
          LongPage: 10,
        }),
        config
      )
      .then((res) => {
        setProduct(res.data.Data);
      });
  };

  useEffect(() => {
    getProductPublic();
  }, [ProductCategoryId, company]);
  return (
    <>
      <SearchFields clickGetProductPublic={getProductPublic} />
      <div className="hidden sm:block sm:ml-2 ml-5">
        <div className="grid grid-cols-4 gap-2 py-6 md:grid-cols-3 lg:grid-cols-3 ">
          {product?.map((item, i) => (
            <AddProduct key={i} item={item} />
          ))}
        </div>
      </div>
      <div className="sm:hidden">
        <div className="grid grid-cols-1 gap-1 py-6">
          {product?.map((item, i) => (
            <AddProduct key={i} item={item} />
          ))}
        </div>
      </div>
    </>
  );
}

export default ViewProduct;
