import { useState, useContext, useRef } from "react";
import { StateContext } from "../../provider/StateProvider";
import axios from "axios";
const apiInsertUserWeb = axios.create({
  baseURL: `http://108.181.162.121:3617/adminSoftSystem/registry/insertUserWeb/`,
});

function Register() {
  const { company, alertMsg, loadIndicator, load, updateUserData } = useContext(StateContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");

  const refEmail = useRef(null);
  const refPassword = useRef(null);
  const refRepeatPassword = useRef(null);
  const refFirstName = useRef(null);
  const refLastName = useRef(null);
  const refPhone = useRef(null);

  const register = (e) => {
    e.preventDefault();
    loadIndicator(true);
    if (load) return;
    if (email == "") {
      alertMsg(
        "Error, la dirección de correo electrónico es requerida",
        refEmail
      );
      loadIndicator(false);
      return;
    } else if (password === "") {
      alertMsg("Error, la contraseña es requerida", refPassword);
      loadIndicator(false);
      return;
    } else if (repeatPassword === "") {
      alertMsg(
        "Error, la confirmar contraseña es requerida",
        refRepeatPassword
      );
      loadIndicator(false);
      return;
    } else if (firstName === "") {
      alertMsg("Error, el nombre es requerido", refFirstName);
      loadIndicator(false);
      return;
    } else if (lastName === "") {
      alertMsg("Error, el apellido es requerido", refLastName);
      loadIndicator(false);
      return;
    } else if (phone === "") {
      alertMsg("Error, el teléfono es requerido", refPhone);
      loadIndicator(false);
      return;
    }

    let data = {
      UserName: email,
      Name: firstName,
      LastName: lastName,
      OccupationId: "",
      Department: "",
      Phone: phone,
      PasswordHash: password,
    };
    let config = {
      headers: {
        Authorization: "Bearer " + company?.AccessToken,
      },
    };
    apiInsertUserWeb
      .post("/", JSON.stringify(data), config)
      .then((res) => {
        if (
          res?.data?.DESCRIPTION_ERROR === undefined ||
          res?.data?.DESCRIPTION_ERROR === null ||
          res?.data?.DESCRIPTION_ERROR === ""
        ) {
          alertMsg(
            "Su registro fue satisfactoriamente salvado en nuestro sistema, te damos la bienvenida",
            null,
            "Gracia",
            "S"
          );
          localStorage.removeItem('currentUser');
          localStorage.setItem('currentUser', null);
          localStorage.setItem('currentUser', JSON.stringify(res?.data.Data));
          updateUserData();
        } else {
          alertMsg(res?.data?.DESCRIPTION_ERROR);
        }

        setEmail("");
        setPassword("");
        setRepeatPassword("");
        setFirstName("");
        setLastName("");
        setPhone("");
        loadIndicator(false);
      })
      .catch((err) => {
        if (
          (err?.data?.DESCRIPTION_ERROR !== undefined &&
            err?.data?.DESCRIPTION_ERROR !== null) ||
          err?.data?.DESCRIPTION_ERROR !== ""
        ) {
          alertMsg(err?.data?.DESCRIPTION_ERROR);
        } else {
          alertMsg(err);
        }
        loadIndicator(false);
      });
  };

  return (
    <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="w-full max-w-md space-y-8">
        <div>
          <img
            className="mx-auto h-12 w-auto"
            src={company?.Photo?.Photo}
            alt={company?.Name}
          />
          <h1 className="mt-6 text-center text-4xl font-bold tracking-tight text-gray-900">
            {company?.Name}
          </h1>
          <p className="mt-2 text-center text-sm text-gray-600">
            <a className="font-medium text-indigo-600 hover:text-indigo-500">
              {company?.Slogan}
            </a>
          </p>
          <h2 className="mt-6 text-center text-2xl font-bold tracking-tight text-gray-900">
            Crear su cuenta
          </h2>

          <form className="mt-8 space-y-6" onSubmit={register}>
            <div className="relative z-0 mb-6 w-full group">
              <input
                type="email"
                name="floating_email"
                id="floating_email"
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=""
                required=""
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                ref={refEmail}
              />
              <label
                htmlFor="floating_email"
                className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                Dirección de correo electrónico
              </label>
            </div>
            <div className="relative z-0 mb-6 w-full group">
              <input
                type="password"
                name="floating_password"
                id="floating_password"
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                required=""
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                ref={refPassword}
              />
              <label
                htmlFor="floating_password"
                className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                Contraseña
              </label>
            </div>
            <div className="relative z-0 mb-6 w-full group">
              <input
                type="password"
                name="repeat_password"
                id="floating_repeat_password"
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                required=""
                value={repeatPassword}
                onChange={(e) => setRepeatPassword(e.target.value)}
                ref={refRepeatPassword}
              />
              <label
                htmlFor="floating_repeat_password"
                className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                Confirmar contraseña
              </label>
            </div>
            <div className="grid md:grid-cols-2 md:gap-6">
              <div className="relative z-0 mb-6 w-full group">
                <input
                  type="text"
                  name="floating_first_name"
                  id="floating_first_name"
                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  required=""
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  ref={refFirstName}
                />
                <label
                  htmlFor="floating_first_name"
                  className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Nombre
                </label>
              </div>
              <div className="relative z-0 mb-6 w-full group">
                <input
                  type="text"
                  name="floating_last_name"
                  id="floating_last_name"
                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  required=""
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  ref={refLastName}
                />
                <label
                  htmlFor="floating_last_name"
                  className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Apellido
                </label>
              </div>
            </div>
            <div className="grid md:grid-cols-2 md:gap-6">
              <div className="relative z-0 mb-6 w-full group">
                <input
                  type="tel"
                  pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                  name="floating_phone"
                  id="floating_phone"
                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  required=""
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  ref={refPhone}
                />
                <label
                  htmlFor="floating_phone"
                  className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Teléfono ###-###-####
                </label>
              </div>
              <div className="relative z-0 mb-6 w-full group"></div>
            </div>
            <div>
              <button
                type="submit"
                className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                <span className="absolute inset-y-0 left-0 flex items-center pl-3"></span>
                Regístrate
              </button>
              <a
                href={"/ecommerce/login"}
                type="button"
                className="group relative flex w-full justify-center rounded-md border border-transparent bg-orange-600 py-2 px-4 text-sm font-medium text-white hover:bg-orange-700 focus:outline-none focus:ring-2
               focus:ring-indigo-500 focus:ring-offset-2 my-2"
              >
                <span className="absolute inset-y-0 left-0 flex items-center pl-3"></span>
                Iniciar sesión
              </a>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
export default Register;
