import React from "react";
import NavbarMartecleaning from "./NavbarMartecleaning";
import CompMartecleaning from "./CompMartecleaning";
export default function page() {
  return (
    <div>
      <NavbarMartecleaning  />
      <CompMartecleaning></CompMartecleaning>
      
    </div>
  );
}