import { useContext } from "react";
import { StateContext } from "../../provider/StateProvider";

function ProductCategory() {
  const { productCategory } = useContext(StateContext);
  return (
    <div>
      <ul className="items-center justify-center space-y-8 md:flex md:space-x-6 md:space-y-0 mt-3">
        {productCategory.map((item, i) => (
          <li className="text-white hover:text-indigo-200 " key={i}>
            <a
              
              href={
                "/" +
                item?.Description.replace(" ", "")
                  .replace(" ", "")
                  .replace(" ", "")
              }
              className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-5  rounded-md text-sm font-medium"
            >
              {item?.Description}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default ProductCategory;
