import { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import { StateContext } from "../../provider/StateProvider";
import StarIcon from "@material-ui/icons/Star";
import Citas from "../citas/Citas";
import { data } from "autoprefixer";

function ViewProductDetail() {

  const [item, setItem] = useState({});
  const { company, formatToNumber, apiInventory, alertMsg} = useContext(StateContext);
  const reviews = { href: "#", average: 4, totalCount: 117 };
  const [images, setImages] = useState([]);
  const [verAgenda, setVerAgenda] = useState(false);
  const urlImg =
    "http://108.181.162.121:3608/adminSoftSystem/api/viewPhotoProduct/";
  var productUnitBilling = 0;

  const [price1, setPrice1] = useState("0.00");

  const addImg = (PhotoProduct) => {
    let _return = [];
    let _urlImagen = "https://via.placeholder.com/350x380/6495ED/000000";
    if (PhotoProduct.length > 0) {
      PhotoProduct.map((itemImag, i) => {
        _return.push({
          original: urlImg +
          companyId +
            "/" + itemImag._id,
          thumbnail: urlImg +
          companyId +
            "/" + itemImag._id,
        });
      });
    } else {
      _return.push({
        original: _urlImagen,
        thumbnail: _urlImagen,
      });
    }
    return _return;
  };

  let { id, companyId } = useParams();
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const getProductByID = (id) => {
    let config = {
      headers: {
        Authorization: "Bearer " + company?.AccessToken,
      },
    };
    apiInventory
      .post(
        "/getProduct/",
        JSON.stringify({
          CompanyId: companyId,
          UserLogged: "",
          Id: id,
          Name: "",
          NumberPage: 1,
          LongPage: 10,
        }),
        config
      )
      .then((res) => {
        if (res.data.Data.length > 0) {
          setItem(res.data.Data[0]);

          setImages(addImg(res.data.Data[0].PhotoProduct));
          productUnitBilling = res.data.Data[0].ProductUnit.filter(
            (n) => n.UnitBillingOrderQuote === "X"
          );
          let price = productUnitBilling.length > 0
            ? formatToNumber(productUnitBilling[0].Price1)
            : "0.00";
          setPrice1(price);
        }
      });
  }
  useEffect(() => {
    getProductByID(id);
  }, []);

  const closeModal = () =>{
    if(localStorage.getItem('currentUser') !== null)
      setVerAgenda(!verAgenda);
    else
      alertMsg("Si no tiene una cuenta de usuario por favor registrarse. Si tienes una cuenta por favor ingresar con su usuario.");
  }
  return (
    <>
      <div className="shadow mx-auto mt-6 max-w-2xl sm:px-6 lg:grid lg:max-w-7xl  lg:gap-x-8 lg:px-8 lg:grid-cols-2 justify-content-center">

        <div className="mt-2">
          {images ? <ImageGallery items={images} className="h-full" /> : null}

        </div>
        <div className="mt-2">
          <div className="mt-10 lg:col-span-2 lg:border-r lg:border-gray-200 lg:pr-8">
            <form className="mt-10">
              <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
                {item.Name}
              </h1>
              <div className="overflow-y-scroll mt-2.5 mb-15">
                <div
                  className="h-48 "
                  dangerouslySetInnerHTML={{ __html: item.Description }}
                />

                
              </div>
              <div className="mt-6">
                  <h2 className="sr-only">Product information</h2>
                  <p className="text-3xl tracking-tight text-gray-900 text-right"> ${price1}</p>

                  {/* Reviews */}
                  <div className="mt-6">
                    <h3 className="sr-only">Reviews</h3>
                    <div className="flex items-center">
                      <div className="flex items-center">
                        {[0, 1, 2, 3, 4].map((rating) => (
                          <StarIcon
                            key={rating}
                            className={classNames(
                              reviews.average > rating
                                ? "text-gray-900"
                                : "text-gray-200",
                              "h-5 w-5 flex-shrink-0"
                            )}
                            aria-hidden="true"
                          />
                        ))}
                      </div>
                      <p className="sr-only">{reviews.average} out of 5 stars</p>
                      <a
                        href={reviews.href}
                        className="ml-3 text-sm font-medium text-indigo-600 hover:text-indigo-500"
                      >
                        {reviews.totalCount} reviews
                      </a>
                    </div>
                  </div>

                </div>
              <div className="flex -space-x-2 overflow-hidden justify-content-center">

                <div className="md:grid  md:gap-6">

                  <div className="mt-5 md:col-span-2 md:mt-0">

                    <div className=" sm:overflow-hidden sm:rounded-md justify-content-center">
                      <div className="space-y-6 bg-white px-4 py-5 sm:p-6 ">
                        <div className="col-span-3 sm:col-span-2 ">
                          <img
                            className="inline-block h-20 w-20 rounded-full ring-2 ring-white "
                            src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                            alt=""
                          />
                        </div>
                        <div>
                          <div className="col-span-3 sm:col-span-2">
                            <label htmlFor="about" className="block text-sm font-medium text-gray-700">
                              About
                            </label>
                            <div className="mt-1">

                            </div>
                            <p className="mt-2 text-sm text-gray-500">
                              Brief description for your profile. URLs are hyperlinked.
                            </p>
                          </div>
                        </div>
                      </div>
                      <button
                        type="button"
                        onClick={closeModal}
                        className="mt-10 flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 py-3 px-8 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        Agenda una cita
                      </button>
                    </div>

                  </div>
                </div>
              </div>

            </form>
          </div>
        </div>
      </div>
      {verAgenda ? <Citas closeModal={closeModal}/> : null}
    </>
  );
}

export default ViewProductDetail;
