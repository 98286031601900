import React from "react";
import imgHome from "../../img/home.jpg";

function Header() {
  const imgHomeUrl = imgHome;
  return (
    <div>
      <img
        className="relative w-full h-full xs:absolute"
        src={imgHomeUrl}
        alt=""
      />
      
    </div>
  );
}

export default Header;
