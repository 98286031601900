import React from "react";
import "./SobreNosotros1034223.css";
function SobreNosotros1034223() {
  return (
    <section id="services" className="services section-bg">
      <div className="container">
        <header className="section-header">
          <h3>Sobre nosotros</h3>
          <p>Aquí encuentras lo que su empresa necesita.</p>
        </header>

        <section className="body-font text-gray-600 ">
          <div className="container mx-auto px-5 py-10">
            <div className="-m-4 flex flex-wrap">
              <p>
                Desarrollamos en múltiples entornos, para ayudar a su empresa
                con herramientas que le permita tener una mejor competitividad
                en el mercado.
              </p>
              <p>
                Nuestro equipo es un grupo de profesionales especializados en
                las áreas de ingeniería de sistemas, contabilidad y asuntos
                fiscales. Con más de 18 años de experiencia en el mercado.
              </p>
              <ul className="list-disc">
                <li>- Un equipo que trabaja utilizando metodologías ágiles.</li>
                <li>- Desarrollo utilizando github.</li>
                <li>
                  - Equipo especializado en programación con lenguajes de alto
                  nivel.
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    </section>
  );
}

export default SobreNosotros1034223;
