import "./Indicators.css";
function Indicators() {
  return (
    <div>
      <div className="justify-center  items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto  mx-auto max-w-full max-h-full">
          {/*content*/}
          <div className="rounded-lg shadow-lg relative flex flex-col w-full ">
            <div className=" relative  ">
              <div className="spinner"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="justify-center  items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none top-1/3">
        <h1 className="text-zinc-50 skew-y-0 text-5xl"> Cargando... </h1>
      </div>

      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </div>
  );
}

export default Indicators;
