import { React, useContext } from "react";
import Search from "@material-ui/icons/Search";

import ProductCategoryCenterSearch from "../productCategory/ProductCategoryCenterSearch";
import { StateContext } from "../../provider/StateProvider";

const TabFilter = () => {
  const { city } = useContext(StateContext);
  return (
    <>
      <div className="justify-center items-center  flex  fixed top-80 w-full z-80 ">
        <div className="w-100 ">
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded">
            <h3 className="text-4xl font-normal leading-normal mt-0 mb-2 text-white text-center w-100">
              Encuentra la propiedad que se ajusta a tus necesidades
            </h3>
            <h3 className="text-4xl font-normal leading-normal mt-0 mb-2 text-white text-center w-100">
              y logra tu sueño de ser el dueño...
            </h3>
            <ProductCategoryCenterSearch />
            <div className="tab-content tab-space w-full">
              <div className="w-full" id="link1">
                <div className="relative flex w-full flex-wrap items-stretch mb-3">
                  <select
                    id="city"
                    name="city"
                    autoComplete="city-name"
                    className="px-3 py-3 placeholder-gray-300 text-gray-600 relative bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full pr-10"
                  >
                    {city.map((item, i) => (
                      <option key={i}>{item.Description}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TabFilter;
